import React, { useState, useEffect } from "react"
import $ from 'jquery';
import { Link } from "gatsby";
import './assets/styles/_index.scss';
import { navigate } from "@reach/router";
import LogoFull from "../../images/logo-full.svg";
import { isLoggedIn, getUser } from "../../utility/Auth";
import { isEmpty } from "lodash";
import ProfileIcon from "./assets/images/profile-default-pic.png";

const VerticalSidebar = (props) => {
  const [isAuth, setIsAuth] = useState(isLoggedIn());
  const [userinfo, setUserInfo] = useState(getUser());
  useEffect(() => {
    if (!isAuth) {
      navigate('/auth/login')
    }
  }, [isAuth]);

  // Menu collape
  const menuClick = (e) => {
    $("body").toggleClass("dashboard-menu-open");
  }
  // Menu collape

  return (
    <>
      <div className="vertical-menu">
        <div className="vertical-menu-main">
          <div className="dashboard-logo"><Link to="/">
            <div className="full-logo">
              <img src={LogoFull} className="img-fluid my-2" alt=""/>
            </div>
            <h1 className="half-logo">D</h1></Link>
          </div>
          <ul className="list-inline person-details-wrapper d-flex align-items-center">
            <li className="list-inline-item"><img src={ProfileIcon} className="img-fluid" alt=""/></li>
            <li className="list-inline-item">
              <div className="person-wrapper">
                <div className="person-name" style={{ paddingTop: '10px' }}>{userinfo.firstname} {userinfo.lastname}</div>
                <div className="person-desig">DNG</div>
              </div>
            </li>
          </ul>
        </div>
        <div className="vertical-menu-sub">
          <ul className="list-unstyled">
            <li>
              <Link to="/new-developments/list"
                className={
                  (((props.action === 'Add') || (props.action === 'Edit')) && props.menuActive !== "settings") ?
                    ((props.menuActive === "new-developments") ? "active d-flex align-items-center disable-menu" : "d-flex align-items-center disable-menu") :
                    ((props.menuActive === "new-developments") ? "active d-flex align-items-center" : "d-flex align-items-center")
                }>
                <div className="icon-vertical-menu">
                  <i className="icon icon-new-developments"></i>
                </div>
                <span>New Developments</span></Link>
            </li>
            <li>
              <Link to="/properties/list"
                className={
                  (((props.action === 'Add') || (props.action === 'Edit')) && props.menuActive !== "settings") ?
                    ((props.menuActive === "properties") ? "active d-flex align-items-center disable-menu" : "d-flex align-items-center disable-menu") :
                    ((props.menuActive === "properties") ? "active d-flex align-items-center" : "d-flex align-items-center")
                }>
                <div className="icon-vertical-menu">
                  <i className="icon icon-properties"></i>
                </div>
                <span>Properties</span></Link>
            </li>
            {
              (!isEmpty(userinfo.hasSuperAdminRole)) && (
                <div>
                  <li>
                    <Link to="/settings"
                      className={
                        (((props.action === 'Add') || (props.action === 'Edit')) && props.menuActive !== "settings") ?
                          ((props.menuActive === "settings") ? "active d-flex align-items-center disable-menu" : "d-flex align-items-center disable-menu") :
                          ((props.menuActive === "settings") ? "active d-flex align-items-center" : "d-flex align-items-center")
                      }>
                      <div className="icon-vertical-menu">
                        <i className="icon icon-config"></i>
                      </div>
                      <span>Settings</span>
                    </Link>
                  </li>
                </div>
              )
            }
          </ul>
        </div>
        <div className="vertical-menu-logout">
          <div className="burger-menu-icon">
            <a href="javascript:;" onClick={menuClick}>
              <i className="bi bi-chevron-left"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerticalSidebar